.footer {
    background: #1b233d;
    padding: 0.2rem;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 540px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}
.footer img {
    -webkit-filter: hue-rotate(175deg);
    filter: hue-rotate(175deg);
    height: 0.75rem;
}
.footer a {
    color: #bcbcbc;
}
.footer .active a {
    color: #fff;
}
.footer .active img {
    -webkit-filter: hue-rotate(45deg);
    filter: hue-rotate(45deg);
}
